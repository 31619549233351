import { api } from '@/api/main'
import { api as main_node } from '@/api/mainNode'
import { i18n } from '@/plugins/i18n'

const moment = require('moment')
const shopMatherStatus = JSON.parse(localStorage.getItem('shopMainStatus'))
const path = shopMatherStatus ? 'mainshop' : 'subshop'
import { removeFormatPrice } from '@/plugins/filters'

// i18n({ locale: "en" })
function sumdate(string, status) {
  if (string != null) {
    const year = string.substring(0, 4)
    const sumYear = parseInt(year) + 543
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const months_th_mini =
      status === 'UpPer'
        ? [
          i18n.t('january'),
          i18n.t('february'),
          i18n.t('march'),
          i18n.t('april'),
          i18n.t('may'),
          i18n.t('june'),
          i18n.t('july'),
          i18n.t('august'),
          i18n.t('september'),
          i18n.t('october'),
          i18n.t('november'),
          i18n.t('december'),
        ]
        : [
          i18n.t('jan'),
          i18n.t('feb'),
          i18n.t('mar'),
          i18n.t('apr'),
          i18n.t('may'),
          i18n.t('jun'),
          i18n.t('jul'),
          i18n.t('aug'),
          i18n.t('sep'),
          i18n.t('oct'),
          i18n.t('nov'),
          i18n.t('dec'),
        ]
    const date = `${day} ${months_th_mini[mount - 1]} ${sumYear}`

    return date
  }

  return string
}

function sumdatetime(string, lang) {
  if (i18n.locale === 'en') {
    return sumdatetimeen(string)
  }
  if (string && string !== '-') {
    const year = string.substring(0, 4)
    const sumYear = parseInt(year) + 543
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const time = string.substring(11, 16)
    const months_th_mini = [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ]
    const date = `${day} ${months_th_mini[mount - 1]} ${sumYear}  ${time}`

    return date
  }

  return string
}

function sumdatetimeen(string) {
  if (string && string !== '-') {
    const year = string.substring(0, 4)

    // let sumYear = parseInt(year) + 543;
    const mount = string.substring(5, 7)
    const day = string.substring(8, 10)
    const time = string.substring(11, 16)
    const months_th_mini = [
      'Jan.',
      'Feb.',
      'Mar.',
      'Apr.',
      'May',
      'Jun.',
      'Jul.',
      'Aug.',
      'Sep.',
      'Oct.',
      'Nov.',
      'Dec.',
    ]
    const date = `${day} ${months_th_mini[mount - 1]} ${year}  ${time}`

    return date
  }

  return string
}

async function removeComma(data) {
  try {
    const response = data.map(item => {
      for (const [key, value] of Object.entries(item)) {
        if (
          `${item[key]}`.includes('.') &&
          /([0-9])\.[0-9][0-9]/.test(`${item[key]}`) &&
          !/([ก-๙A-Za-z])/.test(`${item[key]}`)
        ) {
          item[key] = parseFloat(`${item[key]}`.split(',').join(''))
        }
      }

      return item
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

async function htmlToExcel(payload) {
  try {
    let { html, filename } = payload
    const excelFileName = 'excel_table_data'
    const TableDataType = 'application/vnd.ms-excel'
    const htmlTable = html.replace(/ /g, '%20')
    filename = filename ? `${filename}.xls` : `${excelFileName}.xls`
    const excelFileURL = document.createElement('a')
    document.body.appendChild(excelFileURL)
    if (navigator.msSaveOrOpenBlob) {
      const blob = new Blob(['\ufeff', htmlTable], {
        type: TableDataType,
      })
      navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      excelFileURL.href = `data:${TableDataType}, ${htmlTable}`
      excelFileURL.download = filename
      excelFileURL.click()
    }
  } catch (err) {
    console.log(err)
  }
}

async function reportcourse2(payload) {
  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    const { data, datadetail } = await main_node.post({
      path: `${path}/reportcourse`,
      body: { ...body, lang: i18n.locale },
    })
    if (data.list) {
      arrFetchData = data.list.map((item, i) => ({ number: i + 1, ...item }))
      arrFetchData.push({
        order_create: `${i18n.t('total')}`,
        orderdetail_price: data.price,
        orderdetail_discount: data.discount,
        orderdetail_total: data.total,
      })
    }
    let dataDetail = []
    if (datadetail.customer_list) {
      dataDetail = datadetail.customer_list.map((item, i) => ({
        number: i + 1,
        ...item,
        customer_tags: `${item.customer_tags ? item.customer_tags : ''}`,
      }))
      dataDetail.push({
        customer_tags: `${i18n.t('total')}`,
        orderdetail_price: datadetail.price,
        orderdetail_discount: datadetail.discount,
        orderdetail_total: datadetail.total,
      })
    }

    return { data: arrFetchData, datadetail: dataDetail }
  } catch (err) {
    console.log(err)
  }
}

// สรุปยอดขายยา/อุปกรณ์
async function reportDrug2(payload) {
  try {
    let arrFetchData = []
    let arrFetchCustomer = []
    let {} = payload
    const body = payload

    const {
      data,
      total_price,
      total_discount,
      total_amount,
      customerData,
      total_unit,
    } = await main_node.post({
      path: `${path}/reportdrug`,
      body: { ...body, lang: i18n.locale },
    })
    arrFetchData = data.map((item, i) => {
      // const date = item.date ? sumdatetime(item.date) : ''
      return { number: i + 1, ...item }
    })
    arrFetchData.push({
      druglot_name: '',
      name: `${i18n.t('total')}`,
      amount: total_unit,
      price: total_price,
      discount: total_discount,
      total: total_amount,
    })

    return { data: arrFetchData, customerData: customerData }
  } catch (err) {
    console.log(err)
  }
}

// รายงานการใช้คอร์ส ไม่แสดงรายการยา
async function reportServe2(payload) {

  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    let dataMain = await main_node.post({
      path: `${path}/reportserve`,
      body: { ...body, lang: i18n.locale },
    })
    const { data, price_sum_pay, drug_amount_total, drug_cost_total } = dataMain

    if (data) {
      arrFetchData = data.map((item, i) => ({
        number: i + 1,
        ...item,
        customer_birthdate: item.customer_birthdate ? sumdatetime(item.customer_birthdate, 'UpPer') : '',
        customer_tags: `${item.customer_tags ? item.customer_tags : ''}`,
        course_type_text: `${
          item.course_type === '1'
            ? `${i18n.t('limited_time')}`
            : item.course_type === '2'
              ? `${i18n.t('limited_day')}`
              : item.course_type === '3'
                ? `${i18n.t('limited_drug')}`
                : `-`
        }`,
        // serve_date: sumdatetime(item.serve_date),
        // order_create: item.order_create ? sumdatetime(item.order_create) : '-',
      }))
      arrFetchData.push({
        course_name: i18n.t('total'),
        course_price: price_sum_pay,
        drug_amount: drug_amount_total,
        drug_cost: drug_cost_total,
      })
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// รายงานการใช้คอร์ส แสดงรายการยา
async function reportServeShow(payload) {

  try {
    let arrFetchData = []
    let {} = payload
    const body = payload
    let dataMain = await main_node.post({
      path: `${path}/reportServeOld`,
      body: { ...body, lang: i18n.locale },
    })
    const { data, price_sum_pay, drug_amount_total, drug_cost_total } = dataMain

    if (data) {
      arrFetchData = data.map((item, i) => ({
        number: i + 1,
        ...item,
        customer_birthdate: item.customer_birthdate ? sumdatetime(item.customer_birthdate, 'UpPer') : '',
        customer_tags: `${item.customer_tags ? item.customer_tags : ''}`,
        course_type_text: `${
          item.course_type === '1'
            ? `${i18n.t('limited_time')}`
            : item.course_type === '2'
              ? `${i18n.t('limited_day')}`
              : item.course_type === '3'
                ? `${i18n.t('limited_drug')}`
                : `-`
        }`,
        // serve_date: sumdatetime(item.serve_date),
        // order_create: item.order_create ? sumdatetime(item.order_create) : '-',
      }))
      arrFetchData.push({
        course_name: i18n.t('total'),
        course_price: price_sum_pay,
        drug_amount: drug_amount_total,
        drug_cost: drug_cost_total,
      })
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

// สรุปยอดขายลูกค้า
async function reportCustomer2(payload) {

  try {
    let arrFetchData = []
    const response = await main_node.post({
      path: `${path}/reportCustomer`,
      body: { ...payload, lang: i18n.locale },
    })

    const { data, count, count_of_page, segment } = response
    if (data) {
      arrFetchData = data.map((item, i) => {
        const customer_tags = `${item.customer_tags ? item.customer_tags : ''}`
        delete item.customer_id_pri
        delete item.customer_age

        return {
          number: i + 1,
          ...item,
          customer_tags,
        }
      })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData, pageData: { count, count_of_page, segment } }
  } catch (err) {
    console.log(err)
  }
}

async function payoutreport2(payload) {

  try {
    let arrFetchData = []
    const response = await main_node.post({
      path: 'reportPayOut2',
      body: { ...payload, lang: i18n.locale },
    })

    const {
      data,
      s_sum_before_total,
      s_sum_discount,
      s_sum_after_total,
      s_orderpay_balance,
      s_pay_by_cash,
      s_pay_by_bank,
      s_pay_by_credit,
      s_pay_by_application,
      s_pay_by_other,
      s_expenses_by_cash,
      s_expenses_by_bank,
      s_expenses_by_credit,
      s_expenses_by_application,
      s_expenses_by_other,
      s_reduce_debt,
      s_total_payment,
    } = response
    if (data) {
      arrFetchData = data.map((item, i) => {
        return {
          number: i + 1,
          ...item,
        }
      })
      arrFetchData.push({
        order_id: i18n.t('total'),
        sum_before_total: s_sum_before_total,
        sum_discount: s_sum_discount,
        sum_after_total: s_sum_after_total,
        orderpay_balance: s_orderpay_balance,
        pay_by_cash: s_pay_by_cash,
        pay_by_bank: s_pay_by_bank,
        pay_by_credit: s_pay_by_credit,
        pay_by_application: s_pay_by_application,
        pay_by_other: s_pay_by_other,
        expenses_by_cash: s_expenses_by_cash,
        expenses_by_bank: s_expenses_by_bank,
        expenses_by_credit: s_expenses_by_credit,
        expenses_by_application: s_expenses_by_application,
        expenses_by_other: s_expenses_by_other,
        reduce_debt: s_reduce_debt,
        total_payment: s_total_payment,
      })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

async function reportIncome(payload) {

  try {
    let arrFetchData = []
    let list1 = []
    let list2 = []
    let list3 = []
    let list4 = []
    let list5 = []

    const response = await main_node.post({
      path: `${path}/reportIncome`,
      body: { ...payload, lang: i18n.locale },
    })

    const {
      data,
      s_sum_before_total,
      s_sum_discount,
      s_sum_after_total,
      s_orderpay_balance,
      s_pay_by_cash,
      s_pay_by_bank,
      s_pay_by_credit,
      s_pay_by_application,
      s_pay_by_other,
      s_expenses_by_cash,
      s_expenses_by_bank,
      s_expenses_by_credit,
      s_expenses_by_application,
      s_expenses_by_other,
      s_reduce_debt,
      s_total_payment,
      cash_list,
      bank_list,
      other_list,
      application_list,
      credit_list,
      s_cash_list,
      s_bank_list,
      s_other_list,
      s_application_list,
      s_credit_list,
      s_order_pointsave,
    } = response
    if (data) {
      arrFetchData = data.map((item, i) => {
        delete item.sum_before_total
        return {
          number: i + 1,
          sum_before_total: removeFormatPrice(item.sum_discount) + removeFormatPrice(item.sum_after_total),
          ...item,
        }
      })
      list1 = cash_list.map((item, i) => {
        return {
          number: i + 1,
          ...item,
        }
      })
      list2 = bank_list.map((item, i) => {
        return {
          number: i + 1,
          ...item,
        }
      })
      list3 = other_list.map((item, i) => {
        return {
          number: i + 1,
          ...item,
        }
      })
      list4 = application_list.map((item, i) => {
        return {
          number: i + 1,
          ...item,
        }
      })
      list5 = credit_list.map((item, i) => {
        return {
          number: i + 1,
          ...item,
        }
      })

      arrFetchData.push({
        order_id: i18n.t('total'),
        sum_before_total: removeFormatPrice(s_sum_before_total),
        sum_discount: s_sum_discount,
        order_pointsave: s_order_pointsave,
        sum_after_total: s_sum_after_total,
        orderpay_balance: s_orderpay_balance,
        pay_by_cash: s_pay_by_cash,
        pay_by_bank: s_pay_by_bank,
        pay_by_credit: s_pay_by_credit,
        pay_by_application: s_pay_by_application,
        pay_by_other: s_pay_by_other,
        expenses_by_cash: s_expenses_by_cash,
        expenses_by_bank: s_expenses_by_bank,
        expenses_by_credit: s_expenses_by_credit,
        expenses_by_application: s_expenses_by_application,
        expenses_by_other: s_expenses_by_other,
        reduce_debt: s_reduce_debt,
        total_payment: s_total_payment,
      })
      list1.push({
        shop_bank_name: i18n.t('total'),
        orderpay_current: s_cash_list,
      })
      list2.push({
        shop_bank_name: i18n.t('total'),
        orderpay_current: s_bank_list,
      })
      list3.push({
        shop_bank_name: i18n.t('total'),
        orderpay_current: s_other_list,
      })
      list4.push({
        shop_bank_name: i18n.t('total'),
        orderpay_current: s_application_list,
      })
      list5.push({
        shop_bank_name: i18n.t('total'),
        orderpay_current: s_credit_list,
      })
    } else {
      arrFetchData = []
    }

    return {
      data: arrFetchData,
      cash_list: list1,
      bank_list: list2,
      other_list: list3,
      application_list: list4,
      credit_list: list5,
    }
  } catch (err) {
    console.log(err)
  }
}

async function salessummary2(payload) {

  try {
    let arrFetchData = []

    const response = await main_node.post({
      path: `${path}/salessummary2`,
      body: { ...payload, lang: i18n.locale },
    })

    const {
      data,
    } = response
    if (data) {
      arrFetchData = data.map((item, i) => {
        delete item.sum_before_total
        return {
          number: i + 1,
          ...item,
        }
      })
    // arrFetchData.push({
    //   order_id: i18n.t('total'),
    //   sum_before_total: removeFormatPrice(s_sum_before_total),
    //   sum_discount: s_sum_discount,
    //   order_pointsave: s_order_pointsave,
    //   sum_after_total: s_sum_after_total,
    //   orderpay_balance: s_orderpay_balance,
    //   pay_by_cash: s_pay_by_cash,
    //   pay_by_bank: s_pay_by_bank,
    //   pay_by_credit: s_pay_by_credit,
    //   pay_by_application: s_pay_by_application,
    //   pay_by_other: s_pay_by_other,
    //   expenses_by_cash: s_expenses_by_cash,
    //   expenses_by_bank: s_expenses_by_bank,
    //   expenses_by_credit: s_expenses_by_credit,
    //   expenses_by_application: s_expenses_by_application,
    //   expenses_by_other: s_expenses_by_other,
    //   reduce_debt: s_reduce_debt,
    //   total_payment: s_total_payment,
    // })
    } else {
      arrFetchData = []
    }

    return {
      data: arrFetchData,
    }
  } catch (err) {
    console.log(err)
  }
}

async function reportPayment2(payload) {

  try {
    let arrFetchData = []
    const response = await main_node.post({
      path: `${path}/reportPayment2`,
      body: { ...payload, lang: i18n.locale },
    })

    const {
      data,
      s_sum_check_df,
      s_course_df,
      s_sum_check,
      s_sum_drug,
      s_sum_equipment,
      s_sum_course,
      s_sum_member,
      s_sum_all,
    } = response
    if (data) {
      arrFetchData = data.map((item, i) => {
        return {
          number: i + 1,
          ...item,
        }
      })
      arrFetchData.push({
        order_id: i18n.t('total'),
        sum_check_df: s_sum_check_df,
        sum_course_df: s_course_df,
        sum_check: s_sum_check,
        sum_drug: s_sum_drug,
        sum_equipment: s_sum_equipment,
        sum_course: s_sum_course,
        sum_member: s_sum_member,
        sum_all: s_sum_all,
      })
    } else {
      arrFetchData = []
    }

    return { data: arrFetchData }
  } catch (err) {
    console.log(err)
  }
}

export {
  removeComma,
  reportPayment2,
  htmlToExcel,
  reportcourse2,
  reportDrug2,
  reportServe2,
  reportIncome,
  reportCustomer2,
  reportServeShow,
  payoutreport2,
  salessummary2,
}
